import React from "react"
import styled from "styled-components"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import SearchBar from "../components/search/search-bar"

const NotFoundPage = ({ location }) => {
  const pathName = typeof window != "undefined" ? window.location.pathname : ""

  return (
    <Layout location={location}>
      <SEO title="404: Not found" location={location} />
      <h1>NOT FOUND</h1>
      <p>The page that you requested doesn’t exist</p>
      <Styled404SearchResults>
        <h4>
          Search below, perhaps we can still find what you were looking for.
        </h4>
        <SearchBar query={pathName.replace(/(?:-|\/)/gi, " ")} />
      </Styled404SearchResults>
    </Layout>
  )
}

export default NotFoundPage

// ==========
// 	 STYLES
// ==========
const Styled404SearchResults = styled.div`
  > h4 {
    padding: 3rem 0;
  }
`
